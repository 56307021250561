<template>
  <q-layout :view="$q.platform.is.mobile ? 'hhh lpR lFf' : 'hHh lpR lfr'">
    <q-header class="bg-transparent text-black" height-hint="40">
      <q-toolbar v-if="$q.platform.is.mobile">
        <div class="col-auto">
          <q-btn flat icon="app:menu-bars" class="be-icon-20" @click="leftDrawerOpen = true" />
        </div>
        <div class="col text-center">
          <router-link v-if="settingsStore.website.logo" to="/" :title="settingsStore.website.name">
            <picture>
              <source :srcset="$assetsUrl() + settingsStore.website.logo.split('.')[0] + '.webp'" type="image/webp" />
              <img
                :src="$assetsUrl() + settingsStore.website.logo"
                :alt="settingsStore.website.name"
                style="max-width: 100%; max-height: 40px"
              />
            </picture>
          </router-link>
        </div>
        <div class="col-auto">
          <q-img
            v-if="currentLanguageCountry !== null"
            :src="'https://assets.boosteat.com/shop-images/countries/' + currentLanguageCountry + '.png'"
            loading="eager"
            style="width: 25px; height: 25px; margin-right: 20px; border-radius: 50%; opacity: 0.6"
            @click="showChangeLanguage = true"
          />
        </div>
      </q-toolbar>
      <q-toolbar v-if="$q.platform.is.mobile !== true" class="bg-white">
        <div class="row be-desktop-container be-main-lr">
          <div class="col-auto">
            <router-link v-if="settingsStore.website.logo" to="/" :title="settingsStore.website.name">
              <picture>
                <source :srcset="$assetsUrl() + settingsStore.website.logo.split('.')[0] + '.webp'" type="image/webp" />
                <img
                  :src="$assetsUrl() + settingsStore.website.logo"
                  :alt="settingsStore.website.name"
                  style="max-width: 100%; max-height: 69.5px; margin-top: 5px; margin-right: 30px"
                />
              </picture>
            </router-link>
          </div>
          <div class="col text-center be-desktop-menu">
            <q-tabs no-caps inline-label indicator-color="accent" align="left" narrow-indicator>
              <q-route-tab
                to="/"
                replace
                style="height: 80px"
                icon="app:menu"
                :label="$t('menu')"
                :title="$t('menu')"
              />
              <q-route-tab
                v-if="settingsStore.show_pages.about_us && !settingsStore.show_pages.jobs"
                to="/page/about-us"
                replace
                icon="app:about"
                :label="$t('about-us.title')"
                :title="$t('about-us.title')"
              />
              <q-btn-dropdown
                v-if="settingsStore.show_pages.jobs"
                icon="app:about"
                auto-close
                no-caps
                menu-anchor="bottom left"
                menu-self="top left"
                :label="$t('about-us.title')"
              >
                <q-list>
                  <q-item clickable to="/page/about-us">
                    <q-item-section avatar>
                      <q-icon name="app:about" />
                    </q-item-section>
                    <q-item-section>{{ $t('about-us.title') }}</q-item-section>
                  </q-item>
                  <q-item clickable to="/jobs">
                    <q-item-section avatar>
                      <q-icon name="app:jobs" />
                    </q-item-section>
                    <q-item-section>{{ $t('jobs.title') }}</q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
              <q-route-tab
                v-if="settingsStore.show_pages.events"
                to="/events"
                replace
                icon="app:events"
                :label="$t('events.title')"
                :title="$t('events.title')"
              />
              <q-route-tab
                v-if="settingsStore.show_pages.reservations"
                to="/reservations"
                replace
                icon="app:reservations"
                :label="$t('reservations.title')"
                :title="$t('reservations.title')"
              />
              <template v-for="(page, pindex) in otherPages" :key="'page-menu-' + pindex">
                <q-route-tab
                  v-if="page.type === 'other'"
                  no-caps
                  :icon="page.icon !== null ? `img:${$assetsUrl()}${page.icon}` : null"
                  :label="page.name"
                  :title="page.name"
                  :to="'/' + page.url"
                />
                <q-route-tab
                  v-else-if="page.type === 'link'"
                  no-caps
                  :icon="page.icon !== null ? `img:${$assetsUrl()}${page.icon}` : null"
                  :label="page.name"
                  :title="page.name"
                  @click="openLink(page.link)"
                />
              </template>
              <q-route-tab
                to="/contact"
                replace
                icon="app:contact"
                :label="$t('contact.title')"
                :title="$t('contact.title')"
              />
            </q-tabs>
          </div>
          <div class="text-right" style="padding-top: 11px">
            <q-btn
              v-if="userStore.isLogged"
              icon="app:orders-bag"
              flat
              no-caps
              stack
              :aria-label="$t('account.orders')"
              class="be-icon-18"
              to="/account/orders"
            />
            <q-btn
              icon="app:account"
              flat
              no-caps
              stack
              class="be-icon-18"
              to="/account"
              :aria-label="$t('account.title')"
            />
            <q-img
              v-if="currentLanguageCountry !== null"
              :src="'https://assets.boosteat.com/shop-images/countries/' + currentLanguageCountry + '.png'"
              loading="eager"
              :alt="'flag-' + currentLanguageCountry"
              style="width: 20px; height: 20px; border-radius: 50%; opacity: 0.6; cursor: pointer; margin-left: 20px"
              @click="showChangeLanguage = true"
            />
          </div>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer v-if="$q.platform.is.mobile" v-model="leftDrawerOpen" class="be-burger-menu">
      <div class="be-main-lr q-mt-lg">
        <div class="text-right">
          <q-btn flat icon="app:back" class="be-btn-round be-icon-12" @click="leftDrawerOpen = false" />
        </div>
        <div class="text-center q-mt-lg">
          <q-btn
            v-if="userStore.isLogged"
            flat
            no-caps
            icon="app:account"
            class="be-icon-accent be-icon-18 be-client-name"
            :label="userStore.name === null ? $t('account.title') : userStore.name"
            to="/account"
          />
          <q-btn
            v-else
            flat
            icon="app:contact-data"
            class="be-btn-l bg-black text-white be-icon-18 q-mb-sm btn-around"
            style="width: 186px"
            :label="$t('account.title')"
            to="/auth/login"
            align="around"
          />
        </div>
        <q-list>
          <q-item clickable to="/">
            <q-item-section avatar>
              <q-icon color="black" name="app:menu" class="be-icon-18" />
            </q-item-section>
            <q-item-section>{{ $t('menu') }}</q-item-section>
          </q-item>
          <q-item
            v-if="settingsStore.show_pages.about_us && !settingsStore.show_pages.jobs"
            clickable
            to="/page/about-us"
          >
            <q-item-section avatar>
              <q-icon color="black" name="app:about" class="be-icon-18" />
            </q-item-section>
            <q-item-section>{{ $t('about-us.title') }}</q-item-section>
          </q-item>
          <q-expansion-item v-if="settingsStore.show_pages.jobs" icon="app:about" :label="$t('about-us.title')">
            <q-list>
              <q-item clickable to="/page/about-us">
                <q-item-section avatar>
                  <q-icon color="black" name="app:about" class="be-icon-18" />
                </q-item-section>
                <q-item-section>{{ $t('about-us.title') }}</q-item-section>
              </q-item>
              <q-item v-if="settingsStore.show_pages.jobs" clickable to="/jobs">
                <q-item-section avatar>
                  <q-icon color="black" name="app:jobs" class="be-icon-18" />
                </q-item-section>
                <q-item-section>{{ $t('jobs.title') }}</q-item-section>
              </q-item>
            </q-list>
          </q-expansion-item>
          <q-item v-if="settingsStore.show_pages.events" clickable to="/events">
            <q-item-section avatar>
              <q-icon color="black" name="app:events" class="be-icon-18" />
            </q-item-section>
            <q-item-section>{{ $t('events.title') }}</q-item-section>
          </q-item>
          <q-item v-if="settingsStore.show_pages.reservations" clickable to="/reservations">
            <q-item-section avatar>
              <q-icon color="black" name="app:reservations" class="be-icon-18" />
            </q-item-section>
            <q-item-section>{{ $t('reservations.title') }}</q-item-section>
          </q-item>
          <template v-for="(page, index) in otherPages" :key="'po' + index">
            <q-item v-if="page.type === 'other'" :label="page.name" :to="'/' + page.url">
              <q-item-section avatar>
                <q-icon
                  color="black"
                  :name="page.icon !== null ? `img:${$assetsUrl()}${page.icon}` : null"
                  class="be-icon-18"
                />
              </q-item-section>
              <q-item-section>{{ page.name }}</q-item-section>
            </q-item>
            <q-item v-else-if="page.type === 'link'" :label="page.name" clickable @click="openLink(page.link)">
              <q-item-section avatar>
                <q-icon
                  color="black"
                  :name="page.icon !== null ? `img:${$assetsUrl()}${page.icon}` : null"
                  class="be-icon-18"
                />
              </q-item-section>
              <q-item-section>{{ page.name }}</q-item-section>
            </q-item>
          </template>
          <q-item clickable to="/contact">
            <q-item-section avatar>
              <q-icon color="black" name="app:contact" class="be-icon-18" />
            </q-item-section>
            <q-item-section>{{ $t('contact.title') }}</q-item-section>
          </q-item>
        </q-list>
      </div>
      <div class="text-center">
        <div class="row">
          <div v-for="(account, index) in settingsStore.socialMediaAccounts" :key="index" class="col">
            <q-btn
              :icon="`app:social-icon-${account.social_media}-black`"
              class="be-icon-26"
              :aria-label="account.social_media"
              @click="openLink(account.link)"
            />
          </div>
        </div>
        <div class="q-mb-md q-pt-md">
          <template v-for="(page, index) in footerPageLeft" :key="'fpe-' + index">
            <div class="text-center col q-pt-md">
              <router-link
                v-if="page.type === 'other'"
                :to="'/' + page.url"
                no-caps
                :title="page.name"
                style="text-decoration: none; font-weight: 400; white-space: nowrap"
                >{{ page.name }}
              </router-link>
              <a
                v-else
                style="text-decoration: none; font-weight: 400; white-space: nowrap"
                target="_blank"
                :href="page.link"
                :title="page.name"
                >{{ page.name }}</a
              >
            </div>
          </template>
          <template v-for="(page, index) in footerPageCenter" :key="'fpc-' + index">
            <div class="text-center col q-pt-md">
              <router-link
                v-if="page.type === 'other'"
                :to="'/' + page.url"
                :title="page.name"
                no-caps
                style="text-decoration: none; font-weight: 400; white-space: nowrap"
                >{{ page.name }}
              </router-link>
              <a
                v-else
                style="text-decoration: none; font-weight: 400; white-space: nowrap"
                target="_blank"
                :title="page.name"
                :href="page.link"
                >{{ page.name }}</a
              >
            </div>
          </template>
          <template v-for="(page, index) in footerPageRight" :key="'fpr-' + index">
            <div class="text-center col q-pt-md">
              <router-link
                v-if="page.type === 'other'"
                :to="'/' + page.url"
                no-caps
                :title="page.name"
                style="text-decoration: none; font-weight: 400; white-space: nowrap"
                >{{ page.name }}
              </router-link>
              <a
                v-else
                style="text-decoration: none; font-weight: 400; white-space: nowrap"
                target="_blank"
                :title="page.name"
                :href="page.link"
                >{{ page.name }}</a
              >
            </div>
          </template>
          <div
            v-if="settingsStore.footer_content !== null && settingsStore.footer_content.length > 0"
            class="be-desktop-container be-main-lr"
            v-html="footerContent"
          ></div>
          <div :class="footerPageLeft.length || footerPageCenter.length || footerPageRight.length > 0 ? 'q-pt-lg' : ''">
            <router-link to="/legal/terms-and-conditions">{{ $t('legal.title') }}</router-link>
          </div>
        </div>
        <div v-if="settingsStore.localization.language === 'ro'" class="row q-col-gutter-sm q-ma-sm">
          <div class="col">
            <a href="https://anpc.ro/ce-este-sal/" target="_blank">
              <img
                src="https://assets.boosteat.com/shop-images/legal/anpc-sal.webp"
                class="full-width"
                alt="ANPC SAL"
              />
            </a>
          </div>
          <div class="col">
            <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" target="_blank">
              <img
                src="https://assets.boosteat.com/shop-images/legal/anpc-sol.webp"
                class="full-width"
                alt="ANPC SOL"
              />
            </a>
          </div>
        </div>
        <a
          href="https://www.boosteat.com/"
          class="text-grey"
          title="Powered by Boost Eat"
          style="
            text-decoration: none;
            font-weight: 400;
            white-space: nowrap;
            margin-top: 20px;
            margin-bottom: 20px;
            display: block;
          "
          target="_blank"
          >Powered by Boost Eat</a
        >
      </div>
    </q-drawer>

    <q-page-container>
      <router-view class="bg-white be-desktop-container" style="padding-bottom: 40px" />
    </q-page-container>

    <q-footer
      v-if="$q.platform.is.mobile && (allowOrdering || callWaiter)"
      class="bg-white text-primary text-center be-footer"
    >
      <template v-if="callWaiter && allowOrdering === false">
        <div class="row">
          <div class="col">
            <q-btn
              icon="app:waiter"
              no-caps
              flat
              stack
              class="be-icon-16"
              :label="$t('table-ordering.call-waiter')"
              @click="requestCallWaiter"
            />
          </div>
          <div class="col">
            <q-btn
              icon="app:receipt-pos"
              no-caps
              flat
              stack
              class="be-icon-16"
              :label="$t('table-ordering.ask-for-bill')"
              @click="requestAskForBill"
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="userStore.isLogged" class="row be-footer-logged">
          <template v-if="settingsStore.show_pages.reservations">
            <div class="col">
              <q-btn
                icon="app:contact"
                no-caps
                flat
                stack
                :class="{
                  'be-icon-16': true,
                  'text-accent be-icon-accent': $route.path === '/contact' || $route.path.indexOf('/contact/') !== -1
                }"
                :label="$t('contact.title')"
                to="/contact"
              />
            </div>
            <div class="col">
              <q-btn
                icon="app:reservations"
                no-caps
                flat
                stack
                :class="{ 'be-icon-16': true, 'text-accent be-icon-accent': $route.path === '/reservations' }"
                :label="$t('reservations.title')"
                to="/reservations"
              />
            </div>
          </template>
          <template v-else>
            <div class="col">
              <q-btn
                icon="app:menu"
                no-caps
                flat
                stack
                :class="{
                  'be-icon-16': true,
                  'text-accent be-icon-accent':
                    $route.name === 'homepage' || $route.name.indexOf('category-page') !== -1
                }"
                :label="$t('menu')"
                to="/"
              />
            </div>
            <div class="col">
              <q-btn
                icon="app:contact"
                no-caps
                flat
                stack
                :class="{
                  'be-icon-16': true,
                  'text-accent be-icon-accent': $route.path === '/contact' || $route.path.indexOf('/contact/') !== -1
                }"
                :label="$t('contact.title')"
                to="/contact"
              />
            </div>
          </template>
          <div class="col-auto">
            <q-btn
              icon="app:cart"
              rounded
              class="be-icon-24 bg-black be-cart-button img-no-filter"
              @click="showCart = true"
            >
              <q-badge v-if="cartStore.products.length > 0" color="accent" floating>
                {{ cartStore.products.length }}
              </q-badge>
            </q-btn>
          </div>
          <div class="col">
            <q-btn
              icon="app:orders-bag"
              no-caps
              flat
              stack
              :class="{ 'be-icon-16': true, 'text-accent be-icon-accent': $route.path === '/account/orders' }"
              :label="$t('account.orders')"
              to="/account/orders"
            />
          </div>
          <div class="col">
            <q-btn
              icon="app:account"
              no-caps
              flat
              stack
              :label="$t('account.title')"
              :class="{
                'be-icon-16': true,
                'text-accent be-icon-accent': $route.path === '/account' || $route.path === '/account/benefits'
              }"
              to="/account"
            />
          </div>
        </div>
        <div v-else class="row">
          <div class="col">
            <q-btn
              icon="app:contact"
              no-caps
              flat
              stack
              :label="$t('contact.title')"
              :class="{
                'be-icon-23': true,
                'text-accent be-icon-accent': $route.path === '/contact' || $route.path.indexOf('/contact/') !== -1
              }"
              to="/contact"
            />
          </div>
          <div class="col-auto">
            <q-btn
              icon="app:cart"
              rounded
              class="be-icon-24 bg-black be-cart-button img-no-filter"
              @click="showCart = true"
            >
              <q-badge v-if="cartStore.products.length > 0" color="accent" floating>
                {{ cartStore.products.length }}
              </q-badge>
            </q-btn>
          </div>
          <div class="col">
            <q-btn
              v-if="settingsStore.show_pages.reservations"
              icon="app:reservations"
              no-caps
              flat
              stack
              :class="{ 'be-icon-23': true, 'text-accent be-icon-accent': $route.path === '/reservations' }"
              :label="$t('reservations.title')"
              to="/reservations"
            />
            <q-btn
              v-else
              icon="app:account"
              no-caps
              flat
              stack
              :label="$t('account.title')"
              :class="{
                'be-icon-16': true,
                'text-accent be-icon-accent': $route.path === '/account' || $route.path === '/account/benefits'
              }"
              to="/account"
            />
          </div>
        </div>
      </template>
    </q-footer>

    <q-footer
      v-if="!$q.platform.is.mobile"
      class="bg-white text-grey"
      style="height: 80px; border-top: 3px solid #f7f8f8"
    >
      <div
        v-if="settingsStore.footer_content !== null && settingsStore.footer_content.length > 0"
        class="be-desktop-container be-main-lr"
        v-html="footerContent"
      ></div>
      <div
        v-if="footerPageLeft.length > 0 || footerPageCenter.length > 0 || footerPageRight.length > 0"
        class="row be-desktop-container be-main-lr"
      >
        <div class="col">
          <div v-for="(page, index) in footerPageLeft" :key="'fprl-' + index" class="q-my-sm">
            <router-link
              v-if="page.type === 'other'"
              :to="'/' + page.url"
              no-caps
              style="text-decoration: none; font-weight: 400; white-space: nowrap"
              >{{ page.name }}
            </router-link>
            <a
              v-else
              style="text-decoration: none; font-weight: 400; white-space: nowrap"
              target="_blank"
              :href="page.link"
              >{{ page.name }}</a
            >
          </div>
        </div>
        <div class="col text-center">
          <div v-for="(page, index) in footerPageCenter" :key="'fprc-' + index" class="q-my-sm">
            <router-link
              v-if="page.type === 'other'"
              :to="'/' + page.url"
              no-caps
              style="text-decoration: none; font-weight: 400; white-space: nowrap"
              >{{ page.name }}
            </router-link>
            <a
              v-else
              style="text-decoration: none; font-weight: 400; white-space: nowrap"
              target="_blank"
              :href="page.link"
              >{{ page.name }}</a
            >
          </div>
        </div>
        <div class="col text-right">
          <div v-for="(page, index) in footerPageRight" :key="'fprd-' + index" class="q-my-sm">
            <router-link
              v-if="page.type === 'other'"
              :to="'/' + page.url"
              no-caps
              style="text-decoration: none; font-weight: 400; white-space: nowrap"
              >{{ page.name }}
            </router-link>
            <a
              v-else
              style="text-decoration: none; font-weight: 400; white-space: nowrap"
              target="_blank"
              :href="page.link"
              >{{ page.name }}</a
            >
          </div>
        </div>
      </div>
      <!-- Default footer -->
      <div class="row be-desktop-container be-main-lr" style="margin-top: 15px">
        <div class="col">
          <div class="row">
            <div class="col-auto" style="padding-top: 6px">
              <router-link
                to="/legal/terms-and-conditions"
                class="text-grey q-mr-lg"
                style="text-decoration: none; font-weight: 400; white-space: nowrap"
                >{{ $t('legal.title') }}</router-link
              >
            </div>
            <div class="col-auto">
              <div class="row">
                <div v-for="(account, index) in settingsStore.socialMediaAccounts" :key="index" class="col">
                  <q-btn
                    dense
                    :icon="`app:social-icon-${account.social_media}-black`"
                    class="be-icon-26 q-mr-lg"
                    :aria-label="account.social_media"
                    @click="openLink(account.link)"
                  />
                </div>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
        <div class="col text-center">
          © Copyright {{ new Date().getFullYear() }} {{ settingsStore.company.name }}
          <a
            href="https://www.boosteat.com/"
            class="text-grey"
            style="
              text-decoration: none;
              font-weight: 400;
              white-space: nowrap;
              margin-top: 10px;
              margin-bottom: 20px;
              display: block;
            "
            target="_blank"
            >Powered by Boost Eat</a
          >
        </div>
        <div class="col text-right">
          <div v-if="settingsStore.localization.language === 'ro'" class="row q-col-gutter-sm" style="margin-top: 0px">
            <div class="col"></div>
            <div class="col-auto">
              <a href="https://anpc.ro/ce-este-sal/" target="_blank">
                <img
                  src="https://assets.boosteat.com/shop-images/legal/anpc-sal.webp"
                  style="width: 92px"
                  alt="ANPC SAL"
                />
              </a>
            </div>
            <div class="col-auto">
              <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO" target="_blank">
                <img
                  src="https://assets.boosteat.com/shop-images/legal/anpc-sol.webp"
                  style="width: 92px"
                  alt="ANPC SOL"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </q-footer>

    <cart v-if="showCart" @close-pop-up="showCart = false" />

    <cookies-pop-up v-if="cookieConsent === false" @cookies-consent-accept="acceptCookieConsent" />

    <change-language v-if="showChangeLanguage" @close-pop-up="showChangeLanguage = false" />

    <table-ordering-notification
      v-if="showTableOrderingNotification"
      :allow-ordering="allowOrdering"
      :call-waiter="callWaiter"
      @close-pop-up="showTableOrderingNotification = false"
    />

    <join-group-ordering
      v-if="joinGroupOrderingToken !== null"
      :token="joinGroupOrderingToken"
      @close-pop-up="joinGroupOrderingToken = null"
      @join-success="joinGroupOrderSuccess"
      @redirect="joinGroupOrderingRedirect"
    />

    <q-dialog v-model="showAskForBillDialog" position="bottom">
      <q-card>
        <q-card-section class="text-center">
          <div class="text-h5 q-mb-lg q-mt-md">{{ $t('common.payment-method') }}</div>
          <div class="row q-col-gutter-sm q-mb-md">
            <div class="col">
              <q-btn
                v-close-popup
                class="full-width be-btn-xl"
                :label="$t('payment-methods.words.cash')"
                color="positive"
                @click="sendAskForBill('cash')"
              />
            </div>
            <div class="col">
              <q-btn
                v-close-popup
                :label="$t('payment-methods.words.card')"
                color="blue"
                class="full-width be-btn-xl"
                @click="sendAskForBill('card')"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script>
import { openURL, Cookies, createMetaMixin } from 'quasar'
import { setOptions, bootstrap } from 'vue-gtag'
import CookiesPopUp from '../components/CookiesPopUp.vue'
import Cart from '../components/Cart.vue'

import ChangeLanguage from '../components/ChangeLanguage.vue'
import TableOrderingNotification from '../components/TableOrderingNotification.vue'
import JoinGroupOrdering from '../components/JoinGroupOrdering.vue'

import { mapStores } from 'pinia'
import { useUserStore } from 'stores/user'
import { useSettingsStore } from 'stores/settings'
import { useCartStore } from 'stores/cart'

export default {
  name: 'MainLayout',
  components: {
    'cookies-pop-up': CookiesPopUp,
    cart: Cart,
    'change-language': ChangeLanguage,
    'table-ordering-notification': TableOrderingNotification,
    'join-group-ordering': JoinGroupOrdering
  },
  mixins: [
    createMetaMixin(function mymeta() {
      // if (process.env.BOOSTEAT_MODE === 'table-ordering') {
      //   return {
      //     meta: {}
      //   }
      // }
      let externalResources = []
      // set favicon
      if (this.settingsStore.website.logo !== null) {
        const logoUrl = `${this.$assetsUrl()}${this.settingsStore.website.logo}`
        externalResources.push({
          rel: 'icon',
          href: logoUrl
        })
        externalResources.push({
          rel: 'apple-touch-icon',
          href: logoUrl
        })
        externalResources.push({
          rel: 'mask-icon',
          href: logoUrl
        })
      }
      if (this.settingsStore.website.css !== null) {
        externalResources.push({
          rel: 'stylesheet',
          href: `${process.env.API_URL}public/custom-css/${this.settingsStore.website.id}?v=${Date.now()}`
        })
      }
      this.settingsStore.external_resources.forEach(resource => {
        if (resource.type === 'font') {
          externalResources.push({
            rel: 'stylesheet',
            href: resource.url
          })
        }
      })
      const meta = {
        link: externalResources,
        meta: {}
      }
      if (this.settingsStore.current_domain.indexOf('.preview2.boosteat.com') !== -1) {
        meta.meta['meta-robots'] = {
          name: 'robots',
          content: 'noindex'
        }
      }
      meta.meta['apple-mobile-web-app-title'] = {
        name: 'apple-mobile-web-app-title',
        content: this.settingsStore.website.name
      }
      this.settingsStore.meta_tags.forEach(item => {
        meta.meta[`meta${item.id}`] = item
      })
      return meta
    })
  ],
  data() {
    return {
      showCart: false,
      leftDrawerOpen: false,
      cookieConsent: null,
      externalResources: [],
      showChangeLanguage: false,
      currentLanguageCountry: null,
      showTableOrderingNotification: false,
      allowOrdering: true,
      callWaiter: false,
      showAskForBillDialog: false,
      showGroupOrder: false,
      joinGroupOrderingToken: null
    }
  },
  computed: {
    ...mapStores(useSettingsStore, useUserStore, useCartStore),
    otherPages() {
      return this.settingsStore.pages.filter(
        page => page.position === 'main_menu' && (page.type === 'other' || page.type === 'link')
      )
    },
    footerPageLeft() {
      return this.settingsStore.pages.filter(
        page => page.position === 'footer_left' && (page.type === 'other' || page.type === 'link')
      )
    },
    footerPageCenter() {
      return this.settingsStore.pages.filter(
        page => page.position === 'footer_center' && (page.type === 'other' || page.type === 'link')
      )
    },
    footerPageRight() {
      return this.settingsStore.pages.filter(
        page => page.position === 'footer_right' && (page.type === 'other' || page.type === 'link')
      )
    },
    footerContent() {
      return JSON.parse(this.settingsStore.footer_content)
        .chunks.body.replace('<body ', '<div ')
        .replace('</body>', '</div>')
    }
  },
  mounted() {
    if (
      typeof this.$route.query.join_group_order !== 'undefined' &&
      this.$route.query.join_group_order !== this.cartStore.order_token
    ) {
      this.joinGroupOrderingToken = this.$route.query.join_group_order
    }
    if (this.$q.cookies.has('translate_language') && this.$q.cookies.get('translate_language').length >= 5) {
      this.currentLanguageCountry = this.$q.cookies.get('translate_language').split('-')[1].toLowerCase()
    } else if (this.settingsStore.localization.country_code !== null) {
      this.currentLanguageCountry = this.settingsStore.localization.country_code.toLowerCase()
    }
    if (process.env.BOOSTEAT_MODE === 'table-ordering') {
      if (typeof this.$route.query.available_actions !== 'undefined') {
        switch (this.$route.query.available_actions) {
          case 'order':
            this.allowOrdering = true
            this.$q.localStorage.set('allow_ordering', 'yes')
            this.$q.localStorage.set('message_to_waiter', 'no')
            break
          case 'message_to_waiter':
            this.callWaiter = true
            this.allowOrdering = false
            this.$q.localStorage.set('message_to_waiter', 'yes')
            this.$q.localStorage.set('allow_ordering', 'no')
            break
          default:
            this.allowOrdering = false
            this.$q.localStorage.set('allow_ordering', 'no')
            this.$q.localStorage.set('message_to_waiter', 'no')
            break
        }
      } else {
        if (this.$q.localStorage.has('allow_ordering')) {
          this.allowOrdering = this.$q.localStorage.getItem('allow_ordering') !== 'no'
        } else {
          this.allowOrdering = false
          this.$q.localStorage.set('allow_ordering', 'no')
        }
        if (this.$q.localStorage.has('message_to_waiter')) {
          this.callWaiter = this.$q.localStorage.getItem('message_to_waiter') !== 'no'
        } else {
          this.callWaiter = false
          this.$q.localStorage.set('message_to_waiter', 'no')
        }
      }
      if (this.allowOrdering || this.callWaiter) {
        if (
          typeof this.$route.query.table_number !== 'undefined' &&
          typeof this.$route.query.store_token !== 'undefined'
        ) {
          this.$q.localStorage.set('store_token', this.$route.query.store_token)
          this.cartStore.table_number = this.$route.query.table_number
          this.$q.localStorage.set('table_number', this.$route.query.table_number)
          if (typeof this.$route.query.table_id !== 'undefined') {
            this.$q.localStorage.set('table_id', this.$route.query.table_id)
            this.cartStore.table_id = this.$route.query.table_id
          }
          this.showTableOrderingNotification = true
          if (this.allowOrdering) {
            this.$axios
              .get(`public/table-ordering/products?table_id=${this.cartStore.table_id}`)
              .then(response => {
                const newProducts = this.cartStore.products.filter(product => product.locked === false)
                this.cartStore.products = response.data.products.map(product => {
                  product.photo = null
                  product.locked = true
                  product.options = []
                  product.total_price = product.price
                  product.price = product.price / product.quantity
                  return product
                })

                this.cartStore.products = this.cartStore.products.concat(newProducts)
              })
              .catch(() => {})
          }
        } else if (this.$q.localStorage.has('table_number')) {
          this.cartStore.table_number = this.$q.localStorage.getItem('table_number')
          if (this.$q.localStorage.has('table_id')) {
            this.cartStore.table_id = this.$q.localStorage.getItem('table_id')
          }
        }
      } else {
        this.cartStore.table_number = null
        this.cartStore.table_id = null
        this.$q.localStorage.remove('table_number')
        this.$q.localStorage.remove('table_id')
      }
    } else {
      this.allowOrdering = this.settingsStore.website.allow_ordering
    }
    if (this.settingsStore.localization.country_code !== 'RO') {
      this.$q.localStorage.set('be_cookie_consent', {
        statistically: true,
        marketing: true
      })
      this.loadTrackers()
    } else {
      if (this.$q.localStorage.has('be_cookie_consent')) {
        this.loadTrackers()
      } else {
        this.cookieConsent = false
      }
    }

    // loyalty referral
    if (typeof this.$route.query.referral !== 'undefined') {
      Cookies.set('referral_token', this.$route.query.referral)
    }
    if (this.settingsStore.footer_content !== null && this.settingsStore.footer_content.length > 0) {
      const style = document.createElement('style')
      style.textContent = JSON.parse(this.settingsStore.footer_content).chunks.css
      document.head.append(style)
    }
  },
  methods: {
    openLink(url) {
      if (url.indexOf(`https://${this.settingsStore.website.url}/`) !== -1) {
        this.$router.push(url.replace(`https://${this.settingsStore.website.url}/`, '/'))
      } else {
        openURL(url)
      }
    },
    acceptCookieConsent() {
      this.cookieConsent = true
      this.loadTrackers()
    },
    requestCallWaiter() {
      this.$axios
        .post('public/call-waiter', {
          table_number: this.cartStore.table_number,
          table_id: this.cartStore.table_id,
          store_token: this.$q.localStorage.getItem('store_token')
        })
        .then(response => {
          if (response.data.status === 'OK') {
            this.$q.notify({
              message: response.data.message,
              color: 'positive'
            })
          } else {
            this.$q.notify({
              message: response.data.message,
              color: 'negative'
            })
          }
        })
        .catch(() => {
          this.$q.notify({
            message: this.$t('axios.message-not-sent'),
            color: 'negative'
          })
        })
    },
    requestAskForBill() {
      this.showAskForBillDialog = true
    },
    sendAskForBill(paymentMethod) {
      this.$axios
        .post('public/ask-for-bill', {
          table_number: this.cartStore.table_number,
          table_id: this.cartStore.table_id,
          store_token: this.$q.localStorage.getItem('store_token'),
          payment_method: paymentMethod
        })
        .then(response => {
          if (response.data.status === 'OK') {
            this.$q.notify({
              message: response.data.message,
              color: 'positive'
            })
          } else {
            this.$q.notify({
              message: response.data.message,
              color: 'negative'
            })
          }
        })
        .catch(() => {
          this.$q.notify({
            message: this.$t('axios.message-not-sent'),
            color: 'negative'
          })
        })
    },
    joinGroupOrderingRedirect(redirectUrl) {
      this.joinGroupOrderingToken = null
      this.$router.push(redirectUrl)
    },
    joinGroupOrderSuccess(reload) {
      this.joinGroupOrderingToken = null
      if (reload) {
        window.location.reload()
      }
    },
    loadTrackers() {
      if (this.$q.localStorage.has('be_cookie_consent')) {
        const acceptedCookies = this.$q.localStorage.getItem('be_cookie_consent')
        const { trackers } = this.settingsStore
        Object.keys(trackers).forEach(type => {
          if (type === 'gtag' && acceptedCookies.statistically) {
            if (typeof window.dataLayer === 'undefined') {
              if (this.userStore.id !== null) {
                setOptions({
                  config: {
                    id: trackers[type].code
                  }
                })
              } else {
                setOptions({
                  config: {
                    id: trackers[type].code,
                    params: {
                      user_id: this.userStore.id
                    }
                  }
                })
              }
              bootstrap()
            }
          } else if (type === 'fbpixel' && acceptedCookies.marketing) {
            if (typeof window.fbq === 'undefined') {
              !(function (f, b, e, v, n, t, s) {
                if (f.fbq) return
                n = f.fbq = function () {
                  n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                }
                if (!f._fbq) f._fbq = n
                n.push = n
                n.loaded = !0
                n.version = '2.0'
                n.queue = []
                t = b.createElement(e)
                t.async = !0
                t.src = v
                s = b.getElementsByTagName(e)[0]
                s.parentNode.insertBefore(t, s)
              })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

              fbq('init', trackers[type].code)
              fbq('track', 'PageView')
            }
          } else if (type === 'hotjar' && acceptedCookies.statistically) {
            if (typeof window.hj === 'undefined') {
              ;(function (h, o, t, j, a, r) {
                h.hj =
                  h.hj ||
                  function () {
                    ;(h.hj.q = h.hj.q || []).push(arguments)
                  }
                h._hjSettings = { hjid: trackers[type].code, hjsv: 6 }
                a = o.getElementsByTagName('head')[0]
                r = o.createElement('script')
                r.async = 1
                r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
                a.appendChild(r)
              })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
            }
          }
        })
        if (acceptedCookies.performance && this.$amplitude !== null) {
          this.$amplitude.setOptOut(false)
        }
      }
    }
  }
}
</script>
