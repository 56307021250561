<template>
  <q-dialog
    v-model="showDialog"
    class="be-dialog change-language-dialog"
    style="padding-bottom: 0"
    :maximized="$q.platform.is.mobile == true"
    persistent
    @shake="$emit('closePopUp')"
  >
    <q-card class="be-dialog-card" style="padding-bottom: 0">
      <q-btn
        flat
        icon="app:close"
        class="be-btn-round be-icon-12"
        style="position: absolute; top: 20px; right: 20px; z-index: 9000"
        @click="$emit('closePopUp')"
      />
      <q-card-section class="be-main-lr">
        <div class="q-mt-lg">
          <div class="be-title" v-html="$t('common.select-language')"></div>
        </div>
        <q-input
          v-model="searchLanguage"
          clearable
          type="text"
          outlined
          dense
          debounce="0"
          class="q-mb-md"
          :placeholder="$t('common.search')"
          @update:model-value="filterLanguage"
        >
          <template #prepend>
            <q-icon name="app:search" />
          </template>
        </q-input>
        <div class="q-mb-md">
          <div v-for="(item, index) in filteredLanguages" :key="index" class="q-mb-sm">
            <div class="row">
              <div class="col-auto">
                <q-radio
                  v-model="currentLanguage"
                  style="margin-top: 5px"
                  class="be-icon-20"
                  checked-icon="app:radio-checked"
                  unchecked-icon="app:radio-unchecked"
                  :val="item.code"
                />
              </div>
              <div class="col">
                <q-card
                  style="
                    cursor: pointer;
                    padding: 0;
                    height: 45px;
                    margin-bottom: 8px;
                    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.07);
                    border-radius: 16px;
                  "
                  @click="currentLanguage = item.code"
                >
                  <q-card-section>
                    <div class="row">
                      <div class="col">
                        <q-img
                          :src="'https://assets.boosteat.com/shop-images/countries/' + item.country_code + '.png'"
                          loading="eager"
                          style="width: 25px; height: 25px; margin-right: 5px; border-radius: 50%; opacity: 0.6"
                        />
                        {{ item.country_name }}
                      </div>
                      <div class="col">
                        {{ item.language }}
                      </div>
                    </div>
                  </q-card-section>
                </q-card>
              </div>
            </div>
          </div>
        </div>
      </q-card-section>
      <div class="be-modal-footer q-pb-md change-language-footer" style="min-height: 100px">
        <q-btn
          class="be-btn-l bg-black"
          style="width: 90%; margin: 15px auto; display: block"
          :label="$t('buttons.confirm')"
          @click="changeLanguage"
        />
        <div class="text-center full-width q-mt-md q-mb-md">
          <div class="be-link" @click="$emit('closePopUp')">{{ $t('buttons.cancel') }}</div>
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapState } from 'pinia'
import { useSettingsStore } from 'stores/settings'
import languages from '../assets/languages.json'

export default {
  name: 'ChangeLanguage',
  emits: ['closePopUp'],
  data() {
    return {
      showDialog: true,
      searchLanguage: '',
      currentLanguage: null,
      filteredLanguages: [],
      languages
    }
  },
  computed: {
    ...mapState(useSettingsStore, {
      language: store => store.localization.language,
      country_code: store => store.localization.country_code
    })
  },
  mounted() {
    this.filteredLanguages = this.languages
    if (this.$q.cookies.has('translate_language') && this.$q.cookies.get('translate_language').length === 5) {
      this.currentLanguage = this.$q.cookies.get('translate_language')
    } else {
      switch (this.language) {
        case 'en':
          this.currentLanguage = 'en-US'
          break
        default:
          this.currentLanguage = `${this.language}-${this.language.toUpperCase()}`
      }
    }
  },
  methods: {
    filterLanguage() {
      if (this.searchLanguage === '' || this.searchLanguage === null) {
        this.filteredLanguages = this.languages
        return
      }
      const needle = this.searchLanguage.toLowerCase()
      this.filteredLanguages = this.languages.filter(
        v => v.language.toLowerCase().indexOf(needle) > -1 || v.country_name.toLowerCase().indexOf(needle) > -1
      )
    },
    changeLanguage() {
      const currentLanguageArray = this.currentLanguage.split('-')
      if (currentLanguageArray[0] === this.language && currentLanguageArray[1] === this.country_code) {
        this.$q.cookies.remove('translate_language', {
          expires: 365,
          path: '/'
        })
      } else {
        this.$q.cookies.set('translate_language', this.currentLanguage, {
          expires: 365,
          path: '/'
        })
      }
      window.location.reload()
    }
  }
}
</script>

<style>
@media (min-width: 850px) {
  .change-language-footer {
    position: sticky;
  }
}
</style>
