<template>
  <q-dialog
    v-model="dialog"
    class="be-filters-dialog"
    :position="$q.platform.is.mobile ? 'bottom' : 'standard'"
    @shake="cancel"
  >
    <q-card class="be-dialog-card">
      <q-btn
        flat
        icon="app:close"
        class="be-btn-round be-icon-12"
        style="position: absolute; top: 20px; right: 20px; z-index: 9000"
        @click="$emit('closePopUp')"
      />
      <q-card-section class="be-main-lr">
        <div class="be-icon-52 q-mb-lg q-mt-lg text-center">
          <q-icon name="app:group-order" />
        </div>
        <div v-if="order !== null">
          <div
            class="text-center q-mb-lg q-mt-lg"
            style="font-size: 18px; font-weight: 600"
            v-html="$t('cart.group-order.join-group-order-created-by', { name: order.name })"
          ></div>
          <div v-if="order.delivery_type === 'delivery'" class="text-center q-mb-lg q-mt-lg" style="font-size: 18px">
            <q-icon name="app:location" style="vertical-align: top; margin-top: 4px; margin-right: 10px" />
            <div style="display: inline-block; vertical-align: top">{{ $t('common.delivery-address') }}</div>
            <div class="text-grey">
              {{ order.address_line2 !== '' && order.address_line2 !== null ? `${order.address_line2}, ` : '' }}
              {{ order.address }}
            </div>
          </div>
          <div v-else class="text-center q-mb-lg q-mt-lg" style="font-size: 18px">
            <q-icon name="app:location" style="vertical-align: top; margin-top: 4px; margin-right: 10px" />
            <div style="display: inline-block; vertical-align: top">{{ $t('common.pickup-address') }}</div>
            <div class="text-grey">{{ order.store.name }}</div>
          </div>
          <div class="text-center q-mb-lg q-mt-lg" style="font-size: 18px">
            <q-icon name="app:payments-method" style="vertical-align: top; margin-top: 4px; margin-right: 10px" />
            <div style="display: inline-block; vertical-align: top">{{ $t('common.payment-method') }}:&nbsp;</div>
            <div style="display: inline-block; vertical-align: top">
              {{
                order.group_order_payment_type === 'split'
                  ? $t('cart.group-order.split-order')
                  : $t('cart.group-order.organizer-pay-the-order')
              }}
            </div>
            <div class="text-grey">
              {{ order.group_order_payment_type === 'split' ? $t('cart.group-order.split-order-info') : '' }}
            </div>
          </div>
          <q-btn
            class="be-btn-l bg-black full-width q-mt-sm"
            :label="$t('cart.group-order.join-group-order')"
            @click="joinGroupOrder"
          />
        </div>
        <div class="text-center full-width q-mt-md q-mb-md">
          <div class="be-link" @click="cancel">{{ $t('buttons.cancel') }}</div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapState, mapStores } from 'pinia'
import { useUserStore } from 'stores/user'
import { useCartStore } from 'stores/cart'
import classifyPoint from 'robust-point-in-polygon'
import { useSettingsStore } from 'stores/settings'

import { useMenusStore } from 'src/stores/menus'

export default {
  name: 'JoinGroupOrdering',
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  emits: ['closePopUp', 'redirect', 'joinSuccess'],
  data() {
    return {
      dialog: true,
      order: null,
      delivery_areas: []
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userLogged: 'isLogged'
    }),
    ...mapStores(useCartStore, useMenusStore),
    ...mapState(useSettingsStore, {
      deliveryAreas: store => store.localization.delivery_areas
    })
  },
  mounted() {
    // get group order information from token
    this.$q.loading.show()
    this.$axios
      .get(`/public/group-orders?token=${this.token}`)
      .then(response => {
        if (response.data.status === 'OK') {
          this.order = response.data.order
        } else {
          this.$emit('redirect', `/group-orders/cancelled?message=${response.data.message}`)
        }
        this.$q.loading.hide()
      })
      .catch(() => {
        this.$q.loading.hide()
        this.$emit('closePopUp')
      })
    this.deliveryAreas.forEach(area => {
      const polygon = []
      const polygonArea = JSON.parse(area.polygon)
      if (Array.isArray(polygonArea[0])) {
        polygonArea[0].forEach(position => {
          polygon.push([position.lat, position.lng])
        })
        this.delivery_areas.push({ polygon, info: area })
      }
    })
  },
  methods: {
    cancel() {
      window.history.pushState({}, document.title, window.location.pathname)
      this.$emit('closePopUp')
    },
    joinGroupOrder() {
      if (this.userLogged) {
        this.$q.loading.show()
        this.$axios
          .post('/public/account/group-orders/join', {
            token: this.token
          })
          .then(response => {
            if (response.data.status === 'OK') {
              const reloadPage =
                this.cartStore.store_id !== this.order.store_id ||
                this.cartStore.delivery_type !== this.order.delivery_type

              this.cartStore.$patch({
                group_order: true,
                order_token: this.token,
                participant_token: response.data.participant_token,
                group_order_payment_type: response.data.order.group_order_payment_type,
                geolocation: {
                  lat: response.data.order.latitude !== null ? Number(response.data.order.latitude) : null,
                  lng: response.data.order.longitude !== null ? Number(response.data.order.longitude) : null
                },
                store_id: response.data.order.store_id,
                delivery_type: response.data.order.delivery_type,
                address: response.data.order.address,
                address_line2: response.data.order.address_line2,
                products: []
              })
              this.$q.localStorage.set('be_know_delivery_pickup', true)

              const selectedMenu = this.menusStore.items.find(menu => menu.id === response.data.order.menu_id)
              this.menusStore.setSelected(selectedMenu)
              if (response.data.order.latitude !== null && response.data.order.longitude !== null) {
                let found = false
                this.delivery_areas.forEach(area => {
                  if (
                    found === false &&
                    classifyPoint(area.polygon, [
                      Number(response.data.order.latitude),
                      Number(response.data.order.longitude)
                    ]) !== 1
                  ) {
                    found = true
                    this.storeId = area.info.store_id
                    this.cartStore.saveDeliveryArea(area.info)
                  }
                })
              }
              this.$q.notify({
                message: this.$t('cart.group-order.join-success'),
                color: 'positive',
                position: 'top',
                classes: 'be-icon-white',
                icon: 'app:group-order'
              })
              this.$emit('joinSuccess', reloadPage)
            }
            this.$q.loading.hide()
          })
          .catch(() => {
            this.$q.loading.hide()
            this.$emit('closePopUp')
          })
      } else {
        this.$router.push(`/auth/login?join_group_order=${this.token}`)
      }
    }
  }
}
</script>
