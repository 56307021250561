<template>
  <!--Create a q-dialogue that is maximized to be on the whole page-->
  <q-dialog v-model="dialog" :maximized="$q.platform.is.mobile" class="be-cookie-consent" persistent>
    <!--Split the q-card in 2 section-->
    <q-card style="width: 100%">
      <!--The first section is for a representative image -->
      <q-card-section class="q-card-section-image">
        <!--get the background-->
        <picture v-if="websiteBackground !== null">
          <source :srcset="$assetsUrl() + websiteBackground.split('.')[0] + '.webp'" type="image/webp" />
          <img :src="$assetsUrl() + websiteBackground" :alt="websiteName" />
        </picture>
      </q-card-section>
      <!--Second section is for cookies -->
      <q-card-section class="q-card-section-text">
        <div class="text-center">
          <div style="margin-top: 40px">
            <!--get the logo-->
            <picture v-if="websiteLogo !== null">
              <source :srcset="$assetsUrl() + websiteLogo.split('.')[0] + '.webp'" type="image/webp" />
              <img :src="$assetsUrl() + websiteLogo" :alt="websiteName" style="max-width: 100%; max-height: 40px" />
            </picture>
          </div>

          <div class="welcome">{{ $t('cookies.welcome') }}</div>

          <div class="message-1">{{ $t('cookies.info-message') }}</div>

          <div class="message-2">{{ $t('cookies.info-message-2') }}</div>
          <q-btn
            no-caps
            class="btn-agree"
            :label="$t('cookies.i-agree')"
            data-cy="cookie-accept-button"
            @click="acceptCookieConsent"
          />
          <div class="text-center cookie-settings" style="cursor: pointer" @click="showCookieSettings">
            {{ $t('cookies.settings') }}
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
  <q-dialog
    v-model="settingsDialog"
    class="be-dialog be-cookie-consent"
    :position="$q.platform.is.mobile ? 'bottom' : 'standard'"
  >
    <q-card>
      <q-btn
        flat
        icon="app:close"
        class="be-btn-round be-icon-12"
        style="position: absolute; top: 20px; right: 20px; z-index: 9000"
        @click="settingsDialog = false"
      />
      <q-card-section class="be-main-lr q-pt-md">
        <div class="be-title-info" v-html="$t('cookies.settings-dialog.title')"></div>
        <div class="cookie-info" v-html="$t('cookies.settings-dialog.info')"></div>
        <div>
          <q-list>
            <q-item tag="label">
              <q-item-section>
                <q-item-label>{{ $t('cookies.needed') }}</q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <q-toggle v-model="needed" color="positive" disable></q-toggle>
              </q-item-section>
            </q-item>
            <q-item tag="label">
              <q-item-section>
                <q-item-label>{{ $t('cookies.performance') }}</q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <q-toggle v-model="performance" color="green"></q-toggle>
              </q-item-section>
            </q-item>
            <q-item tag="label">
              <q-item-section>
                <q-item-label>{{ $t('cookies.statistically') }}</q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <q-toggle v-model="statistically" color="green"></q-toggle>
              </q-item-section>
            </q-item>
            <q-item tag="label">
              <q-item-section>
                <q-item-label>{{ $t('cookies.marketing') }}</q-item-label>
              </q-item-section>
              <q-item-section avatar>
                <q-toggle v-model="marketing" color="green"></q-toggle>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
        <q-btn no-caps class="btn-agree text-white full-width" :label="$t('buttons.save')" @click="saveCookieConsent" />
        <!-- <div class="text-center full-width q-mt-md q-mb-md">
          <div class="be-link" @click="setStandartCookieConsent = true">{{ $t('buttons.cancel') }}</div>
        </div> -->
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapState } from 'pinia'
import { useSettingsStore } from 'stores/settings'

export default {
  name: 'CookiesPopUp',
  emits: ['cookiesConsentAccept'],
  data() {
    return {
      dialog: true,
      settingsDialog: false,
      needed: true,
      statistically: true,
      marketing: true,
      performance: true
    }
  },

  computed: {
    ...mapState(useSettingsStore, {
      websiteName: store => store.website.name,
      websiteLogo: store => store.website.logo,
      websiteBackground: store => store.website.background
    })
  },
  mounted() {},
  methods: {
    acceptCookieConsent() {
      this.dialog = false
      this.$q.localStorage.set('be_cookie_consent', {
        statistically: true,
        marketing: true,
        performance: true
      })
      this.$axios.post('public/gdpr-cookie-consent', {
        statistically: true,
        marketing: true,
        performance: true
      })
      this.$emit('cookiesConsentAccept')
    },
    saveCookieConsent() {
      this.dialog = false
      this.$q.localStorage.set('be_cookie_consent', {
        statistically: this.statistically,
        marketing: this.marketing,
        performance: this.performance
      })
      this.$axios.post('public/gdpr-cookie-consent', {
        statistically: this.statistically,
        marketing: this.marketing,
        performance: this.performance
      })
      this.$emit('cookiesConsentAccept')
    },
    setStandartCookieConsent() {
      this.dialog = false
      this.$q.localStorage.set('be_cookie_consent', {
        statistically: false,
        marketing: false,
        performance: false
      })
      this.$axios.post('public/gdpr-cookie-consent', {
        statistically: false,
        marketing: false,
        performance: false
      })
      this.$emit('cookiesConsentAccept')
    },
    showCookieSettings() {
      this.settingsDialog = true
      this.statistically = false
      this.marketing = false
      this.performance = false
    }
  }
}
</script>

<style>
@media (min-width: 850px) {
  .q-card-section-image img {
    visibility: hidden;
  }
  .be-cookie-consent .q-card-section-text {
    box-shadow: none;
    background: transparent;
  }
}
</style>
