<template>
  <q-dialog v-model="dialog" class="be-filters-dialog" position="bottom" @shake="$emit('closePopUp')">
    <q-card class="be-dialog-card">
      <q-btn
        flat
        icon="app:close"
        class="be-btn-round be-icon-12"
        style="position: absolute; top: 20px; right: 20px; z-index: 9000"
        @click="$emit('closePopUp')"
      />
      <q-card-section class="be-main-lr">
        <div class="be-icon-52 q-mb-lg q-mt-lg text-center">
          <q-icon name="app:qr-code" />
        </div>
        <div
          v-if="allowOrdering"
          class="text-center q-mb-lg q-mt-lg"
          style="font-size: 18px; font-weight: 700"
          v-html="$t('table-ordering.what-qr-can-do-info-ordering')"
        ></div>
        <div
          v-if="callWaiter"
          class="text-center q-mb-lg q-mt-lg"
          style="font-size: 18px; font-weight: 700"
          v-html="$t('table-ordering.what-qr-can-do-info-call-waiter')"
        ></div>
        <div class="be-filters-info">
          <q-icon name="app:about" class="be-icon-green be-icon-18" />
          <div v-html="$t('table-ordering.any-time-you-can-ask-for-waiter')"></div>
        </div>
        <q-btn class="be-btn-l bg-black full-width" :label="$t('buttons.continue')" @click="$emit('closePopUp')" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'TableOrderingNotification',
  props: {
    allowOrdering: {
      type: Boolean,
      default: false
    },
    callWaiter: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closePopUp'],
  data() {
    return {
      dialog: true,
      tableNumber: null
    }
  },
  mounted() {
    if (this.$q.localStorage.has('table_number')) {
      this.tableNumber = this.$q.localStorage.getItem('table_number')
    }
  }
}
</script>
